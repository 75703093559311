import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import burger from "../../assets/burger.svg";
import burgerclose from "../../assets/burger-close.svg";
import { menuItems } from "../global/Menu";
import Langs from "../../components/functions/Langs";
import rightarrow from "../../assets/rightarrow.svg";

function Burger() {
  const { t, i18n } = useTranslation();
  const [burgerOpen, setBurgerOpen] = useState(false);
  const content = document.querySelector(".content");

  function openBurger() {
    setBurgerOpen(true);
    content.classList.add("hidden");
  }

  function closeBurger() {
    setBurgerOpen(false);
    content.classList.remove("hidden");
  }

  return (
    <div className="burger">
      {!burgerOpen && (
        <div onClick={openBurger} className="close-burger">
          <img src={burger} title="open-menu" alt="open-menu"></img>
        </div>
      )}

      {burgerOpen && (
        <div className="opened-burger">
          <div className="overlay">
            <div className="overlay__background">
              <div className="overlay__container">
                <div className="burger-langs">
                  <Langs />
                </div>
                <div
                  onClick={closeBurger}
                  className={burgerOpen ? "close-burger blop" : "close-burger"}
                >
                  <img src={burgerclose} alt="close-menu" title="close-menu"></img>
                </div>
                <div className="burger-links">
                  <div className="menu-tag">Menüü</div>

                  {menuItems.map((element) => (
                    <NavLink
                      to={element.address}
                      className="menu-link-burger"
                      activeClassName="menu-link-burger active"
                      onClick={closeBurger}
                    >
                      {t(`${element.name}`)}
                      <img src={rightarrow} alt="arrow-to-the-right"></img>
                    </NavLink>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Burger;
