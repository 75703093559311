import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAxKM9_K0P_kLzUOK9yD62cjkhe0Hrwm-0",
  authDomain: "aurelius-29705.firebaseapp.com",
  databaseURL:
    "https://aurelius-29705-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "aurelius-29705",
  storageBucket: "aurelius-29705.appspot.com",
  messagingSenderId: "811525455870",
  appId: "1:811525455870:web:9541759bb4fa3ec5334aaf",
};

const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;