import React, { useRef, useEffect, useState } from "react";
import "../../css/content/pages/_admin.scss";
import firebase from "../../service/firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import deleteicon from '../../assets/deleteicon.svg';
import addicon from '../../assets/addicon.svg';

function Admin() {
  const boardRef = useRef();
  const registryRef = useRef();
  const companyRef = useRef();
  const dbUrl =
    "https://aurelius-29705-default-rtdb.europe-west1.firebasedatabase.app/volgnikud/-NOKM1laZcxbbGcDPP3O.json";
  const [stockData, setStockData] = useState([]);
  const [showData, setShowData] = useState(false);

  const auth = getAuth();
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        // ...
        setShowData(true);
      } else {
        // User is signed out
        // ...
        setShowData(false);
      }
    });
  }, []);

  useEffect(() => {
    fetch(dbUrl)
      .then((response) => response.json())
      .then((responseBody) => {
        const dataFromDb = [];
        for (const key in responseBody) {
          dataFromDb.push(responseBody[key]);
        }
        setStockData(dataFromDb);
      });
  }, []);

  function onAddDebtor() {
    const newProov = {
      "board": boardRef.current.value,
      "registryCode": registryRef.current.value,
      "company": companyRef.current.value,
    };
    fetch(dbUrl, {
      "method": "POST",
      "body": JSON.stringify(newProov),
      "headers": { "Content-Type": "application/json" },
    });
    window.location.reload(true);
  }

  function deleteDebtor(storedDebtor) {
    const index = stockData.findIndex(
      (element) => element.registryCode === storedDebtor.registryCode
    );
    stockData.splice(index, 1);
    setStockData(stockData.slice());

    fetch(dbUrl, {
      method: "PUT",
      body: JSON.stringify(stockData),
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  return (
    <div>
      {showData && (
        <div className="content">
          <div className="content-real">
            <h1 className="content-heading">Lisa/kustuta</h1>
            <div className="add-new">
              <label>Nimi</label>
              <input
                className="admin-input"
                ref={companyRef}
                type="text"
                required
              />{" "}
              <br />
              <label>Registrikood</label>
              <input
                className="admin-input"
                ref={registryRef}
                type="number"
                required
              />{" "}
              <br />
              <label>Juhatus</label>
              <input
                className="admin-input"
                ref={boardRef}
                type="text"
                required
              />{" "}
              <br />
              <div className="hero-button adder" onClick={() => onAddDebtor()}>
                <img src={addicon} />
              </div>
            </div>
            <div className="deptors-container">
              <div className="table-container">
                <div className="deptors-table adminview">
                  {stockData.map((element) => (
                    <div className="data-list">
                      <div className="row admin">
                        <strong className="admin-view-table">
                          {element.company}
                        </strong>
                        <div className="admin-view-table">
                          {element.registryCode}
                        </div>
                        <div className="admin-view-table">{element.board}</div>
                      </div>
                      <div
                        className="hero-button deleter"
                        onClick={() => deleteDebtor(element)}
                      >
                        <img src={deleteicon} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Admin;
