import React from "react";
import "../../css/content/pages/_contact.scss";
import { useTranslation } from "react-i18next";

function Contact() {
  const [t, i18n] = useTranslation();
  return (
    <div className="content">
      <div className="content-real">
        <h1 className="content-heading">{t("Kontakt")}</h1>
        <div className="contact-container">
          <div className="company">
            <h2 className="important-heading">
              {t("Aurelius Inkasso OÜ")}
              <span className="smaller-text">{t("Registrikood")} 12904439</span>
            </h2>
          </div>

          <div className="info-container">
            <div className="contact-info">
              <div className="contact-row">
                <strong>{t("E-post")}: </strong>{" "}
                <span className="info">info@aurelius.ee</span>
              </div>
              <div className="contact-row">
                <strong>{t("Telefon")}: </strong>{" "}
                <span className="info">+372 555 86 485</span>
              </div>
              <div className="contact-row">
                <strong>{t("Aadress")}: </strong>
                <span className="info">Pärnu mnt 141 Tallinn 11314</span>
              </div>
            </div>

            <div className="contact-info">
              <div className="contact-row">
                <strong>IBAN: </strong>{" "}
                <span className="info">EE091010220246064227</span>
              </div>
              <div className="contact-row">
                <strong>SWIFT/BIC: </strong>{" "}
                <span className="info">EEUHEE2X</span>{" "}
                <span>{t("SEB Pank")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
