import React, { useRef, useEffect, useState } from "react";
import "../../css/content/pages/_debtors.scss";
import { useTranslation } from "react-i18next";

function Debtors() {
  const dbUrl =
    "https://aurelius-29705-default-rtdb.europe-west1.firebasedatabase.app/volgnikud/-NOKM1laZcxbbGcDPP3O.json";
  const [stockData, setStockData] = useState([]);

  useEffect(() => {
    fetch(dbUrl)
      .then((response) => response.json())
      .then((responseBody) => {
        const dataFromDb = [];
        for (const key in responseBody) {
          dataFromDb.push(responseBody[key]);
        }
        setStockData(dataFromDb);
      });
  }, []);
  const [t, i18n] = useTranslation();

  const reversed = stockData.slice(0).reverse();
  return (
    <div className="content">
      <div className="content-real">
        <h1 className="content-heading">{t("Võlgnike nimekiri")}</h1>
        <div className="deptors-container">
          <div className="table-container">
            <span className="subtitle">
              {t("Isikud, kes ei täida oma kohustusi")}
            </span>

            <div className="deptors-table">
              <div className="row headings">
                <div className="col-1-of-3">
                  <strong>{t("Nimi")}</strong>
                </div>
                <div className="col-1-of-3">
                  <strong>{t("Registrikood")}</strong>
                </div>
                <div className="col-1-of-3">
                  <strong>{t("Juhatus")}</strong>
                </div>
              </div>
              <div className="data-rows">
                {stockData.map((element) => (
                  <div className="row">
                    <div className="col-1-of-3 field">{element.company}</div>
                    <div className="col-1-of-3 field">
                      {element.registryCode}
                    </div>
                    <div className="col-1-of-3 field">{element.board}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Debtors;
